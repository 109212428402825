'use client';

import { Form, PinInput, PinInputProps } from '@kamona/components';

import { Column } from '../flex';
import { ResendCode } from './resend-code';

interface OTPInputProps {
  onResend?: () => void;
  enableResendInSeconds?: number;
}

export function OTPInput({
  value,
  length = 6,
  onChange,
  onResend,
  disabled = false,
  enableResendInSeconds,
  ...props
}: Readonly<OTPInputProps> & PinInputProps) {
  const handleChange = async (newValue: string) => {
    if (!/^\d+$/.test(newValue) || newValue.length > length) {
      return;
    }
    onChange?.(newValue);
  };

  return (
    <Column align="center" className="gap-y-[120px] md:gap-y-[128px]">
      <Form>
        <PinInput
          autoFocus
          inputMode="numeric"
          length={length}
          maxLength={length}
          variant="filled"
          onChange={handleChange}
          required
          value={value}
          disabled={disabled}
          pattern={{
            message: 'Only numeric values are allowed',
            value: /^\d+$/,
          }}
          {...props}
        />
      </Form>

      {onResend && <ResendCode {...{ onResend, enableResendInSeconds }} />}
    </Column>
  );
}
