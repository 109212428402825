'use client';

import { startCase } from 'lodash';
import { useRouter } from 'next/navigation';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { PasscodeVerificationForm } from '@/ui/components/passcode-verification-form';

import { useAccountLog } from '@/shared/hooks/use-accounts-log';
import { ROUTES } from '@/shared/routes';

import { useSignInForm } from '../hooks/use-sign-in-form';

export function PasscodeVerification() {
  const router = useRouter();

  const { config } = useSigninFormConfigContext();
  const { getProfile: getProfileFromAccountLog } = useAccountLog();
  const profileCookie = getProfileFromAccountLog(config?.data?.username);

  const { title, subtitle, passcodeLength = 6 } = config.passcodeVerification;

  const { passcode, handleSignIn } = useSignInForm({
    passcodeLength,
  });

  const isValidSession =
    config.data.username || (config.data.idToken && config.data.socialProvider);

  if (!isValidSession) {
    router.push(ROUTES.SIGN_IN.INDEX);
    return null;
  }

  const { firstName, lastName, profileImageUrl } = profileCookie || {};

  const name = [firstName, lastName].map(startCase).join(' ');

  return (
    <PasscodeVerificationForm
      value={passcode}
      passcodeLength={passcodeLength}
      title={
        profileCookie && firstName
          ? `Welcome back, ${startCase(firstName)}`
          : title
      }
      subtitle={subtitle}
      isKnownUser={!!(profileCookie && firstName)}
      recoverPasscodeLink={ROUTES.PASSCODE_RECOVERY.INDEX}
      user={{ name, profileImageUrl }}
      onChange={handleSignIn}
      cancelUrl={ROUTES.SIGN_IN.INDEX}
    />
  );
}
