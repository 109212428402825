import { isFinite } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { PhoneInfoValue } from '@/features/profile/types';

import { Input } from '@/ui/components/input';

import { CountryCode } from '@/shared/types';

import { countryCollection } from '@/lib/constants';

import { CountrySelect, CountrySelectOption } from '../country-select';
import { Flex } from '../flex';
import { TextField } from '../text-field';

interface PhoneInputProps {
  id?: string;
  countries: CountrySelectOption[];
  onChange: (phoneInfo: PhoneInfoValue) => void;
  className?: string;
  value?: PhoneInfoValue;
  error?: Merge<
    FieldError,
    FieldErrorsImpl<{ countryCode: string; number: string }>
  >;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  value: valueFromProps,
  countries,
  onChange,
  className,
  error,
  id,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    valueFromProps?.selectedCountry ?? 'GB',
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    valueFromProps?.number ?? '',
  );

  const handleSelectCountry = useCallback((country: CountryCode) => {
    setSelectedCountry(country);
    setPhoneNumber('');
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;

    if (number) {
      // Check if only numbers are entered
      if (!isFinite(Number(number))) {
        return;
      }
    }

    setPhoneNumber(number.startsWith('0') ? number.slice(1) : number);
  };

  useEffect(() => {
    onChange({
      selectedCountry,
      countryCode: countryCollection[selectedCountry].callingCode,
      number: phoneNumber,
    });
  }, [selectedCountry, phoneNumber, onChange]);

  return (
    <Flex gap="spacing-spacing3" className={className}>
      <CountrySelect
        data-testid="countrycode-dropdown"
        disableName
        disablePlaceholder
        options={countries}
        value={selectedCountry}
        onChange={handleSelectCountry}
        enableDownArrow
      />
      <TextField
        className="w-full"
        renderInput={(props) => (
          <Input
            {...props}
            error={
              !!error?.types?.['phoneNumberRequired'] ||
              !!error?.types?.['validPhoneNumber']
            }
            id={id}
            name="phone"
            value={phoneNumber}
            onChange={handleInputChange}
          />
        )}
      />
    </Flex>
  );
};

export default PhoneInput;
