'use client';

import { notification } from '@kamona/components';
import { isEmpty } from 'lodash';
import { useRouter as useNextRouter } from 'next/navigation';
import { useState } from 'react';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { useSearchParams } from '@/shared/hooks';
import { useDeviceInfo } from '@/shared/hooks/use-device-info';
import useLogin from '@/shared/hooks/use-login';
import { useResetAuthContexts } from '@/shared/hooks/use-reset-is-sso';
import { ROUTES } from '@/shared/routes';
import {
  LoginUserErrorResponse,
  LoginUserPayload,
  LoginUserSuccessResponse,
  getApiErrorMessage,
} from '@/shared/types';

import { useAnalytics } from '@/lib/analytics-service';
import { baseApi, mockApi } from '@/lib/api';
import { STORAGE_KEYS } from '@/lib/constants';
import { useRouter } from '@/lib/navigation';
import { TwoFaService } from '@/lib/two-fa-service/two-fa-service';
import { storeTokens } from '@/lib/utils';

interface UseSigninFormProps {
  passcodeLength?: number;
  redirectTo?: string;
  mock?: boolean;
}

export const useSignInForm = ({ passcodeLength, mock }: UseSigninFormProps) => {
  const analytics = useAnalytics();
  const nextRouter = useNextRouter();
  const router = useRouter();
  const { getParam } = useSearchParams();
  const { resetSigninContext } = useResetAuthContexts();

  const [passcode, setPasscode] = useState('');

  const { config, setConfig } = useSigninFormConfigContext();
  const { deviceInfo } = useDeviceInfo();
  const twoFaService = TwoFaService.use2faService();

  const headers: Record<string, string> = {};

  if (twoFaService?.twoFaConfig?.id) {
    headers['x-moniepoint-fx-2fa-id'] = twoFaService?.twoFaConfig?.id;
  }

  const onTwoFaSuccess = () => {
    if (!config?.data?.passcode) {
      return router.push(ROUTES.SIGN_IN.INDEX);
    }
    handleSignIn(config.data.passcode);
  };

  const on2faExpiry = () => {
    resetSigninContext();
    router.push(ROUTES.SIGN_IN.INDEX);
  };

  TwoFaService.onSuccess = onTwoFaSuccess;
  TwoFaService.onExpiry = on2faExpiry;
  TwoFaService.config = {
    OTP: {
      title: 'Authenticate this device',
      subtitle: `Enter the 6-digit code sent to `,
      passcodeLength: passcodeLength ?? 6,
      showUsername: true,
    },
  };

  const { data } = config ?? {};

  const isValidSession = !!(
    data.username ||
    (data.idToken && data.socialProvider)
  );

  const loginUser = useLogin({
    disableErrorToast: true,
    headers,
    api: mock ? mockApi : baseApi,
    url: mock ? '/logins' : '/identity/v1/logins',
  });

  const handleSignIn = (passcode?: string) => {
    if (!deviceInfo) {
      return;
    }

    setPasscode(passcode ?? '');

    const validPasscode = data.isSSO
      ? true
      : passcode?.length === passcodeLength;

    if (!(isValidSession && validPasscode)) {
      return;
    }

    const loginPayload: LoginUserPayload = {
      ...(data.isSSO
        ? {
            idToken: data.idToken,
            socialProvider: data.socialProvider,
          }
        : {
            username: data.username,
            passcode,
          }),
      deviceInfo,
    };

    loginUser
      .mutateAsync({ data: loginPayload })
      .then((response: LoginUserSuccessResponse, ...args) => {
        // update passcode in signin context

        setConfig({
          ...config,
          data: {
            ...config.data,
            passcode,
          },
        });

        // if response code is 200 save token
        if (response?.accessToken) {
          storeTokens([
            {
              key: STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY,
              token: response.accessToken,
            },
            {
              key: STORAGE_KEYS.CLIENT_REFRESH_TOKEN_STORAGE_KEY,
              token: response.refreshToken,
            },
          ]);
        }
        analytics.track({
          name: 'login_success',
          data: {
            login_method: 'passcode',
          },
        });

        const redirect = getParam('redirect') ?? ROUTES.DASHBOARD.INDEX;

        // move forward with redirect
        nextRouter.push(redirect);
        nextRouter.refresh();
      })
      .catch((error: LoginUserErrorResponse, ...args) => {
        analytics.track({
          name: 'login_failed',
          data: {
            login_method: 'passcode',
          },
        });
        setConfig({
          ...config,
          data: {
            ...config.data,
            passcode,
          },
        });

        const { mfa: twoFa, code } = error.response?.data || {};
        const { isKnown = false } = twoFa?.deviceInfo || {};

        const is2FaRequiredError = code === '2FA_REQUIRED';

        // Show error and reset passcode only if it is not 2FA required error
        if (!is2FaRequiredError) {
          setPasscode('');
          resetSigninContext();
          notification.error({
            title: getApiErrorMessage(error),
            position: 'top-center',
            variant: 'filled',
          });
        }

        if (!isEmpty(twoFa) && is2FaRequiredError) {
          twoFaService.initTwoFaService(twoFa);
        }
      });
  };

  return {
    passcode,
    handleSignIn,
  };
};
