import { isEmpty, omit } from 'lodash';
import { useEffect, useState } from 'react';

import { ROUTES } from '@/shared/routes';

import { useDeviceInfo } from '../../shared/hooks/use-device-info';
import { useTwoFaServiceContext } from '../../shared/providers/two-fa-service-context-provider';
import {
  Create2faSessionProps,
  TwoFaServiceConfig,
  TwoFaStep,
  TwoFaUiConfig,
} from '../../shared/types';
import { useRouter } from '../navigation';
import { useComplete2faStep } from './hooks/use-complete-2fa-step';
import { useCreate2faSession } from './hooks/use-create-2fa-session';
import { useFetch2faById } from './hooks/use-fetch-2fa-by-id';
import { useStart2faStep } from './hooks/use-start-2fa-step';

class TwoFa {
  private _onSuccess: (id: string) => void = () => {};
  private _onExpiry: () => void = () => {};
  private _config: TwoFaUiConfig = {};

  get onSuccess() {
    return this._onSuccess;
  }

  set onSuccess(func) {
    this._onSuccess = func;
  }

  get onExpiry() {
    return this._onExpiry;
  }

  set onExpiry(func) {
    this._onExpiry = func;
  }

  get config() {
    return this._config;
  }

  set config(value) {
    this._config = value;
  }

  getCurrentTwoFaStep = (twoFaConfig: TwoFaServiceConfig) => {
    const { steps } = twoFaConfig || {};
    const pendingSteps = steps
      ?.filter((step: TwoFaStep) =>
        ['CREATED', 'PENDING'].includes(step.status),
      )
      .sort((a, b) => b.step.localeCompare(a.step))
      .map((step) => step.step);

    const currentStep = pendingSteps?.[0];
    return { currentStep };
  };

  waitFor = async (callback: (...args: any) => boolean, timeout: number) =>
    await new Promise((resolve) => {
      setInterval(() => {
        if (callback()) {
          resolve(true);
        }
      }, timeout);
    });

  callOnSuccess(id: string) {
    this.onSuccess(id);
    this.onSuccess = () => {};
  }

  callOnExpiry() {
    this.onExpiry();
    this.onExpiry = () => {};
  }

  use2faService = () => {
    const { twoFaConfig, setTwoFaConfig } = useTwoFaServiceContext();
    const { deviceInfo } = useDeviceInfo();
    const router = useRouter();

    const [otp, setOtp] = useState('');
    const [passcode, setPasscode] = useState('');

    const { id } = twoFaConfig || {};

    const { currentStep } = this.getCurrentTwoFaStep(twoFaConfig);

    const start2faStep = useStart2faStep();
    const complete2fa = useComplete2faStep();
    const twoFaById = useFetch2faById({
      params: { id },
      enabled: !!id,
    });

    const twoFaSession = useCreate2faSession();

    const { isLoading, data: fresh2fa, isError } = twoFaById;

    useEffect(() => {
      if (!isLoading && !isError && fresh2fa) {
        setTwoFaConfig({
          ...fresh2fa,
          to: twoFaConfig.to,
          sumsubAccessToken: twoFaConfig.sumsubAccessToken,
          faceMatchVerificationId: twoFaConfig.faceMatchVerificationId,
        });
      }
    }, [fresh2fa, isError, isLoading, setTwoFaConfig]);

    useEffect(() => {
      if (fresh2fa?.status === 'EXPIRED') {
        this.callOnExpiry();
      }
      if (fresh2fa?.status === 'COMPLETED') {
        this.callOnSuccess(id!);
      }
    }, []);

    const initTwoFaService = (twoFaConfig: TwoFaServiceConfig) => {
      setTwoFaConfig(twoFaConfig);
      router.push(ROUTES.TWO_FA.INDEX);
    };

    const create2faSession = async ({
      username,
      mfaAction,
      redirectFallback,
      redirectTo,
    }: Create2faSessionProps) => {
      if (!deviceInfo) {
        return;
      }

      twoFaSession.mutateAsync(
        {
          data: {
            deviceInfo,
            username,
            mfaAction,
          },
        },
        {
          onSuccess: (response) => {
            setTwoFaConfig(response);
            router.push(
              `${ROUTES.TWO_FA.$INDEX(redirectTo, redirectFallback)}`,
            );
          },
        },
      );
    };

    const startTwoFaStep = () => {
      if (currentStep && id) {
        return start2faStep?.mutateAsync(
          {
            config: {
              url: `/identity/v1/2fas/${id}/steps/${currentStep}/start`,
            },
          },
          {
            onSuccess: (response) => {
              if (!isEmpty(response)) {
                setTwoFaConfig({ ...twoFaConfig, ...response });
              }
            },
          },
        );
      }
    };

    const complete2FaStep = (token: string) => {
      if (currentStep && id && token) {
        return complete2fa.mutateAsync(
          {
            data: {
              token,
            },
            config: {
              url: `/identity/v1/2fas/${id}/steps/${currentStep}/complete`,
            },
          },
          {
            onSuccess: () => {
              twoFaById.refetch().then(async (response) => {
                if (response?.data?.status === 'COMPLETED') {
                  this.callOnSuccess(id);
                } else {
                  router.push(ROUTES.TWO_FA.INDEX);
                }
              });
            },
            onError: () => {
              setOtp('');
              setPasscode('');
            },
          },
        );
      }
    };

    return {
      initTwoFaService,
      currentStep,
      deviceInfo,
      twoFaConfig,
      setTwoFaConfig,
      create2faSession,
      startTwoFaStep,
      complete2FaStep,
      otp,
      setOtp,
      passcode,
      setPasscode,
    };
  };
}

export const TwoFaService = new TwoFa();
